import httpClient from '@/libs/HttpClient';
import md5 from 'js-md5';

export default {
  testHttpBin(){
    return httpClient.get('/get', {
      a: 1,
    }, {
      baseURL: 'http://httpbin.org',
    });
  },
  PostWxapiZnbg(data){  
    var http = window.location.protocol;
    data.sign = md5(data.openid + "Z#yydMc1KiwPoUSh");
    return httpClient.post('/game/Piccsmart/index', data, {
      baseURL: http+'//activity.wtwvision.com',
    });
  },
}