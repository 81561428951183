import axios from 'axios';
// 环境的切换
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

// 添加请求拦截器
axios.interceptors.request.use((config) => {
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default {
  get(url, data, options = {}) {
    return this.request(url, data, 'get', options);
  },
  post(url, data, options = {}) {
    return this.request(url, data, 'post', options);
  },
  put(url, data, options = {}) {
    return this.request(url, data, 'put', options);
  },
  delete(url, data, options = {}) {
    return this.request(url, data, 'delete', options);
  },
  request(url, params, method, options = {}) {
    console.log(options);
    return axios({
      method,
      url,
      data: params,
      ...options,
    });
  },
};
